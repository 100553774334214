<template>
  <div
    v-if="data"
    class="card"
  >
    <div class="card-body">
      <div
        v-if="type === 'Edit'"
        class="d-flex justify-content-end mb-2"
      >
        <b-button
          v-if="data.status === 0"
          variant="info"
          @click="clickUpdateStatus(1)"
        >กำลังดำเนินการ</b-button>
        <b-button
          v-if="data.status === 1"
          class="ml-1"
          variant="primary"
          @click="clickUpdateStatus(2)"
        >เสร็จสิ้น</b-button>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="card-config">
            <div class="card-body">
              <b-img
                v-if="data.img"
                class="w-100 mb-1"
                style="max-height: 250px;cursor:pointer"
                :src="`${$baseURL}${data.img}`"
                @click="indexImg = 0"
              />
              <LightGallery
                :images="images"
                :index="indexImg"
                :disable-scroll="true"
                @close="indexImg = null"
              />
              <div
                v-for="(dt, i) in data.timeline"
                :key="i"
                class="mb-1"
              >
                <div
                  class="d-flex justify-content-start align-items-center mb-1"
                >
                  <!-- avatar -->
                  <b-avatar
                    :src="dt.user.img"
                    :size="i === 0 ? '50' : '30'"
                    class="mr-1"
                  />
                  <!--/ avatar -->
                  <div class="profile-user-info">
                    <h6 class="mb-0">
                      {{ dt.user.profileName }} (<a
                        v-if="data.phone"
                        :href="`tel:${data.phone}`"
                      >{{ data.phone }}</a> <span v-else>ไม่พบหมายเลขโทรศัพท์</span>)
                    </h6>
                    <small class="text-muted">{{
                      dt.createdAt | toTimeThai
                    }}</small>
                  </div>
                  <b-badge
                    v-if="i === 0"
                    class="ml-auto"
                    :variant="
                      data.status === 0
                        ? 'warning'
                        : data.status === 1
                          ? 'info'
                          : data.status === 2
                            ? 'success'
                            : 'secondary'
                    "
                  >
                    {{
                      data.status === 0
                        ? "รอดำเนินการ"
                        : data.status === 1
                          ? "กำลังดำเนินการ"
                          : data.status === 2
                            ? "เสร็จสิ้น"
                            : "ตอบกลับ"
                    }}
                  </b-badge>
                </div>
                <div class="mb-1">
                  {{ dt.message }}
                </div>
                <b-img
                  v-if="dt.img"
                  class="w-100"
                  style="max-height: 250px;cursor:pointer"
                  :src="`${$baseURL}${dt.img}`"
                  @click="indexImg = i + 1"
                />
              </div>
              <div class="mt-1">
                <validation-observer ref="comment">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-textarea
                        id="textarea-formatter"
                        v-model="comment"
                        :state="errors.length > 0 ? false : null"
                        rows="1"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณาระบุตอบกลับ</small>
                    </b-form-group>
                  </validation-provider>
                </validation-observer>
                <b-form-file
                  v-model="img"
                  class="mb-1"
                />
                <div v-if="imgPreview !== ''">
                  <b-img
                    style="width: 150px; height: 150px;cursor:"
                    :src="imgPreview"
                    @click="indexImg = 0"
                  />
                </div>

                <b-button
                  class="mt-1"
                  variant="primary"
                  @click="onReply"
                >ตอบกลับ</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div
              class="col-12 mb-2"
              style="height: 30vh !important"
            >
              <div
                ref="map"
                class="w-100 h-100"
              />
            </div>
            <div class="col-12">
              <div class="card-config">
                <div class="card-body">
                  <h4>ติดตามการดำเนินการ</h4>
                  <div class="mb-2 mt-2">
                    <app-timeline>
                      <app-timeline-item
                        v-for="(dt, i) in data.timeline"
                        :key="i"
                        :variant="
                          dt.status === 0
                            ? 'warning'
                            : dt.status === 1
                              ? 'info'
                              : dt.status === 2
                                ? 'success'
                                : 'secondary'
                        "
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1"
                        >
                          <div>
                            <div
                              class="d-flex justify-content-start align-items-center"
                            >
                              <!-- avatar -->
                              <b-avatar
                                :src="dt.user.img"
                                size="30"
                                class="mr-1"
                              />
                              <!--/ avatar -->
                              <div class="profile-user-info">
                                <h6 class="mb-0">
                                  {{ dt.user.profileName }}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <small class="text-muted">{{
                            dt.createdAt | toTimeThai
                          }}</small>
                        </div>
                        <div style="margin-top: -5px; margin-bottom: 5px">
                          <!-- <small class="text-muted">{{
                            dt.status === 0
                              ? "รอดำเนินการ"
                              : dt.status === 1
                              ? "กำลังดำเนินการ"
                              : dt.status === 2
                              ? "เสร็จสิ้น"
                              : "ตอบกลับ"
                          }}</small> -->
                          <small class="text-muted">{{ dt.message }}</small>
                        </div>
                      </app-timeline-item>
                    </app-timeline>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BAvatar,
  BBadge,
  BImg,
  BFormGroup,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { Loader } from '@googlemaps/js-api-loader'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  components: {
    BButton,
    BAvatar,
    BBadge,
    BImg,
    BFormGroup,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  setup() {},
  data() {
    return {
      map: undefined,
      data: null,
      comment: '',
      type: this.$route.params.type,
      required,
      img: null,
      imgPreview: '',
      indexImg: null,
      images: [],
    }
  },
  watch: {
    img: {
      handler(val) {
        this.imgPreview = val ? URL.createObjectURL(val) : ''
      },
    },
  },
  async created() {
    // await this.onLoader()
    await this.onLoader()
    await this.loadMap()

    // const marker = new google.maps.Marker({
    //   position: { lat: -25.344, lng: 131.031 },
    //   map: this.map,
    // });
    // console.log(marker);

    // await liff.init({
    //   liffId: '1657189238-dz2ML28V', // Use own liffId
    // })
    // const profile = await liff.getProfile()
    // this.profile = profile
  },
  methods: {
    async onLoader() {
      try {
        console.log(this.$route.params.id)
        const res = await this.$store.dispatch(
          'request/getListRequestID',
          this.$route.params.id,
        )
        this.data = {
          ...res.data.item,
          ...{
            timeline: res.data.item.timeline.map(x => ({
              ...x,
              ...{ indexImg: null },
            })),
          },
        }
        this.images = [{ title: 'รูปภาพ', url: `${this.$baseURL}/${this.data.img}` }]
        this.images = this.images.concat(this.data.timeline.map(t => ({ title: 'รูปภาพ', url: `${this.$baseURL}/${t.img}` })))
        // indexImg
      } catch (error) {
        console.log(error)
        this.$router.go(-1)
      }
    },
    async loadMap() {
      const loader = new Loader({
        apiKey: 'AIzaSyA15zZ_DKfS-SJLZ-b4hFFcx-1q4QXFC5E',
        version: 'weekly',
        libraries: ['places'],
      })
      const google = await loader.load()

      const mapOptions = {
        center: {
          lat: parseFloat(this.data.lat),
          lng: parseFloat(this.data.lng),
        },
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
      }
      this.map = new google.maps.Map(this.$refs.map, mapOptions)
      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(this.data.lat),
          lng: parseFloat(this.data.lng),
        },
        map: this.map,
        draggable: false,
      })
      console.log(marker)
    },
    onReply() {
      this.$refs.comment.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append(
            'datas',
            JSON.stringify({
              comment: this.comment,
              id: this.$route.params.id,
              status: this.data.status,
            }),
          )
          formData.append('image', this.img)

          this.$store
            .dispatch('request/replyRequestID', formData)
            .then(res => {
              if (res.data.success) {
                this.comment = ''
                this.$refs.comment.reset()
                this.img = null
                this.onLoader()
              }
            })
        }
      })
    },
    clickUpdateStatus(status) {
      this.$swal({
        title: 'คุณแน่ใจ?',
        text: 'คุณต้องการปรับสถานะคำร้องเรียน',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const res = await this.$store.dispatch('request/updateRequest', {
            status,
            id: this.$route.params.id,
          })
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'คำร้อง',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'เปลี่ยนแปลงสำเร็จ',
              },
            })
            this.$router.push('/')
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'คำร้อง',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'เปลี่ยนแปลงไม่สำเร็จ',
              },
            })
          }
        }
      })
    },
  },
})
</script>
<style lang="scss">
</style>
