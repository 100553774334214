var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.type === 'Edit')?_c('div',{staticClass:"d-flex justify-content-end mb-2"},[(_vm.data.status === 0)?_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){return _vm.clickUpdateStatus(1)}}},[_vm._v("กำลังดำเนินการ")]):_vm._e(),(_vm.data.status === 1)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.clickUpdateStatus(2)}}},[_vm._v("เสร็จสิ้น")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"card-config"},[_c('div',{staticClass:"card-body"},[(_vm.data.img)?_c('b-img',{staticClass:"w-100 mb-1",staticStyle:{"max-height":"250px","cursor":"pointer"},attrs:{"src":("" + _vm.$baseURL + (_vm.data.img))},on:{"click":function($event){_vm.indexImg = 0}}}):_vm._e(),_c('LightGallery',{attrs:{"images":_vm.images,"index":_vm.indexImg,"disable-scroll":true},on:{"close":function($event){_vm.indexImg = null}}}),_vm._l((_vm.data.timeline),function(dt,i){return _c('div',{key:i,staticClass:"mb-1"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center mb-1"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":dt.user.img,"size":i === 0 ? '50' : '30'}}),_c('div',{staticClass:"profile-user-info"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(dt.user.profileName)+" ("),(_vm.data.phone)?_c('a',{attrs:{"href":("tel:" + (_vm.data.phone))}},[_vm._v(_vm._s(_vm.data.phone))]):_c('span',[_vm._v("ไม่พบหมายเลขโทรศัพท์")]),_vm._v(") ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("toTimeThai")(dt.createdAt)))])]),(i === 0)?_c('b-badge',{staticClass:"ml-auto",attrs:{"variant":_vm.data.status === 0
                      ? 'warning'
                      : _vm.data.status === 1
                        ? 'info'
                        : _vm.data.status === 2
                          ? 'success'
                          : 'secondary'}},[_vm._v(" "+_vm._s(_vm.data.status === 0 ? "รอดำเนินการ" : _vm.data.status === 1 ? "กำลังดำเนินการ" : _vm.data.status === 2 ? "เสร็จสิ้น" : "ตอบกลับ")+" ")]):_vm._e()],1),_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(dt.message)+" ")]),(dt.img)?_c('b-img',{staticClass:"w-100",staticStyle:{"max-height":"250px","cursor":"pointer"},attrs:{"src":("" + _vm.$baseURL + (dt.img))},on:{"click":function($event){_vm.indexImg = i + 1}}}):_vm._e()],1)}),_c('div',{staticClass:"mt-1"},[_c('validation-observer',{ref:"comment"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"textarea-formatter","state":errors.length > 0 ? false : null,"rows":"1"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณาระบุตอบกลับ")]):_vm._e()],1)]}}],null,false,3955945121)})],1),_c('b-form-file',{staticClass:"mb-1",model:{value:(_vm.img),callback:function ($$v) {_vm.img=$$v},expression:"img"}}),(_vm.imgPreview !== '')?_c('div',[_c('b-img',{staticStyle:{"width":"150px","height":"150px"},attrs:{"src":_vm.imgPreview},on:{"click":function($event){_vm.indexImg = 0}}})],1):_vm._e(),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":_vm.onReply}},[_vm._v("ตอบกลับ")])],1)],2)])]),_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2",staticStyle:{"height":"30vh !important"}},[_c('div',{ref:"map",staticClass:"w-100 h-100"})]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card-config"},[_c('div',{staticClass:"card-body"},[_c('h4',[_vm._v("ติดตามการดำเนินการ")]),_c('div',{staticClass:"mb-2 mt-2"},[_c('app-timeline',_vm._l((_vm.data.timeline),function(dt,i){return _c('app-timeline-item',{key:i,attrs:{"variant":dt.status === 0
                          ? 'warning'
                          : dt.status === 1
                            ? 'info'
                            : dt.status === 2
                              ? 'success'
                              : 'secondary'}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1"},[_c('div',[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":dt.user.img,"size":"30"}}),_c('div',{staticClass:"profile-user-info"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(dt.user.profileName)+" ")])])],1)]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("toTimeThai")(dt.createdAt)))])]),_c('div',{staticStyle:{"margin-top":"-5px","margin-bottom":"5px"}},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(dt.message))])])])}),1)],1)])])])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }